@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.cdnfonts.com/css/subatomic-screen");
@import url("https://fonts.cdnfonts.com/css/agenor-neue");

* {
  font-family: "Agenor Neue", sans-serif !important;
}

.font-subatomic {
  font-family: "Subatomic Screen Condensed", sans-serif !important;
}

/* width */
body::-webkit-scrollbar {
  width: 5px;
}

/* Track */
body::-webkit-scrollbar-track {
  background-color: black;
  border-radius: 10px;
}

/* Handle */
body::-webkit-scrollbar-thumb {
  background: rgb(155, 150, 0);
  border-radius: 10px;
}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}

body {
  background-color: rgb(
    0,
    0,
    0
  ); /* For browsers that do not support gradients */
  background-image: linear-gradient(to top right, #111827, rgb(48, 48, 48));
  background-attachment: fixed;
}

.rotateLoading {
  animation: rotation 4s infinite linear;
}

.rotateLoadingHover:hover {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
